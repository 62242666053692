<template>
    <v-container class="mt-4 px-5" fluid>
        <v-card class="shadowCard">
            <v-card-title >
      	        Proyecto Rol de Clases
                <v-spacer></v-spacer>
                <v-col cols="12" sm="2" class="d-flex justify-end pa-0 mx-0">
                    <v-btn justify="end" @click="consultar()" color="primary">Actualizar</v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <h2>Filtros</h2>
                <v-divider class="my-3"></v-divider>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-autocomplete filled dense clearable v-model="ciclo" :items="ciclos" label="Selecciona ciclo" persistent-hint
                            single-line hide-details return-object item-text="ciclo" item-value="id_ciclo">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <span>Este ciclo inicia el: {{ ciclo ? ciclo.fecha_inicio_ciclo : '' }}</span>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-radio-group v-model="escuela" row hide-details class="my-2">
                            <v-radio label="Ambos" :value="0"></v-radio>
                            <v-radio label="INBI" :value="1"></v-radio>
                            <v-radio label="FAST" :value="2"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-autocomplete filled :items="[{id: 1, texto: 'Dominical'},{id: 2, texto: 'Sabatino'},{id: 3, texto: 'Entre semana'}]"
                            v-model="frecuencia" item-text="texto" item-value="id" label="Frecuencia" clearable dense single-line hide-details
                        ></v-autocomplete>
            	    </v-col>
                    <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-autocomplete filled :items="niveles"
                            v-model="nivel" item-text="texto" item-value="id" label="Nivel" clearable dense single-line hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-autocomplete filled :items="planteles"
                            v-model="plantel" item-text="plantel" item-value="id_plantel" label="Plantel" clearable dense single-line hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-autocomplete filled :items="horarios"
                            v-model="horario" item-text="texto" item-value="id" label="Horario" clearable dense single-line hide-details
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <br> <br> <br>
                <v-row>
                    <v-col cols="4" sm="3" md="2">
                        <h2>Reglas</h2> 
                    </v-col>
                    <v-col>
                        <v-checkbox class="mt-0" v-model="reglasTodas" :label="'Seleccionar todas las reglas'"
                         dense hide-details></v-checkbox>
                    </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-row>
                    <v-col class="py-0" cols="3">
                        <v-checkbox class="mt-0" v-model="reglaHoras" :label="'Regla - No se empalman horas'"></v-checkbox>
                    </v-col>
                    <v-col class="py-0" cols="3">
                        <v-checkbox class="mt-0" v-model="reglaTeens" :label="'Regla - Unicamente teachers mujeres en Teens/Kids'"></v-checkbox>
                    </v-col>
                    <v-col class="py-0" cols="3">
                        <v-checkbox class="mt-0" v-model="reglaDias" :label="'Regla - Un teacher de lunes a miercoles y otro en jueves y viernes'"></v-checkbox>
                    </v-col>
                    <v-col class="py-0" cols="3">
                        <v-checkbox class="mt-0" v-model="reglaTurno" :label="'Regla - Cada teacher solo puede estar un turno(Matutino, Vespertino) '"></v-checkbox>
                    </v-col>
                    <v-col class="py-0" cols="3">
                        <v-checkbox class="mt-0" v-model="reglaFrec" :label="'Regla - Cada teacher solo puede estar en 2 frecuencias(L-V, S, D)'"></v-checkbox>
                    </v-col>
                    <v-col class="py-0" cols="3">
                        <v-checkbox class="mt-0" v-model="reglaPlanteles" :label="'Regla - Planteles Parejas'"></v-checkbox>
                    </v-col>
                    <v-col class="py-0" cols="3">
                        <v-checkbox class="mt-0" v-model="reglaContinuidad" :label="'Regla - Continuidad'"></v-checkbox>
                    </v-col>
                </v-row>
                <br> <br>
                <h2>Grupos</h2>
                <v-divider class="my-3"></v-divider>
                <v-data-table
                        :headers="gruposHeaders"
                        :items="filterGrupos"
                        item-key="nombre_grupo"
                        class="elevation-1 mb-2"
                    >
                    <template v-slot:item.hora_inicio="{item}">
                        {{item.hora_inicio}} - {{ item.hora_fin }}
                    </template>

                    <template v-slot:item.lu="{item}">
                        <p v-if="item.lunes.activo == 1 && item.lunes.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.lunes.activo == 1"> {{ item.lunes.nombre }} </p>

                        <p v-if="item.sabado.activo == 1 && item.lunes.teacher == 0 && item.sabado.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.sabado.activo == 1 && item.lunes.teacher != 0"> {{ item.lunes.nombre }}</p>
                        <p v-else-if="item.sabado.activo == 1">  {{ item.sabado.nombre }}</p>

                        <p v-if="item.domingo.activo == 1 && item.lunes.teacher == 0 && item.domingo.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.domingo.activo == 1 && item.lunes.teacher != 0">{{ item.lunes.nombre }}</p>
                        <p v-else-if="item.domingo.activo == 1">{{ item.domingo.nombre }}</p>
                    </template>
                    <template v-slot:item.ma="{item}">
                        <p v-if="item.martes.activo == 1 && item.martes.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.martes.activo == 1">{{ item.martes.nombre }}</p>

                        <p v-if="item.sabado.activo == 1 && item.martes.teacher == 0 && item.sabado.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.sabado.activo == 1 && item.martes.teacher != 0">{{ item.martes.nombre }}</p>
                        <p v-else-if="item.sabado.activo == 1">{{ item.sabado.nombre }}</p>

                        <p v-if="item.domingo.activo == 1 && item.martes.teacher == 0 && item.domingo.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.domingo.activo == 1 && item.martes.teacher != 0">{{ item.martes.nombre }}</p>
                        <p v-else-if="item.domingo.activo == 1">{{ item.domingo.nombre }}</p>
                    </template>
                    <template v-slot:item.mi="{item}">
                        <p v-if="item.miercoles.activo == 1 && item.miercoles.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.miercoles.activo == 1">{{ item.miercoles.nombre }}</p>

                        <p v-if="item.sabado.activo == 1 && item.miercoles.teacher == 0 && item.sabado.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.sabado.activo == 1 && item.miercoles.teacher != 0">{{ item.miercoles.nombre }}</p>
                        <p v-else-if="item.sabado.activo == 1">{{ item.sabado.nombre }}</p>

                        <p v-if="item.domingo.activo == 1 && item.miercoles.teacher == 0 && item.domingo.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.domingo.activo == 1 && item.miercoles.teacher != 0">{{ item.miercoles.nombre }}</p>
                        <p v-else-if="item.domingo.activo == 1">{{ item.domingo.nombre }}</p>
                    </template>
                    <template v-slot:item.ju="{item}">
                        <p v-if="item.jueves.activo == 1 && item.jueves.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.jueves.activo == 1">{{ item.jueves.nombre }}</p>

                        <p v-if="item.sabado.activo == 1 && item.jueves.teacher == 0 && item.sabado.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.sabado.activo == 1 && item.jueves.teacher != 0">{{ item.jueves.nombre }}</p>
                        <p v-else-if="item.sabado.activo == 1">{{ item.sabado.nombre }}</p>

                        <p v-if="item.domingo.activo == 1 && item.jueves.teacher == 0 && item.domingo.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.domingo.activo == 1 && item.jueves.teacher != 0">{{ item.jueves.nombre }}</p>
                        <p v-else-if="item.domingo.activo == 1">{{ item.domingo.nombre }}</p>
                    </template>
                    <template v-slot:item.vi="{item}">
                        <p v-if="item.viernes.activo == 1 && item.viernes.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.viernes.activo == 1">{{ item.viernes.nombre }}</p>

                        <p v-if="item.sabado.activo == 1 && item.viernes.teacher == 0 && item.sabado.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.sabado.activo == 1 && item.viernes.teacher != 0">{{ item.viernes.nombre }}</p>
                        <p v-else-if="item.sabado.activo == 1">{{ item.sabado.nombre }}</p>

                        <p v-if="item.domingo.activo == 1 && item.viernes.teacher == 0 && item.domingo.teacher == 0">Teacher sin asignar</p>
                        <p v-else-if="item.domingo.activo == 1 && item.viernes.teacher != 0">{{ item.viernes.nombre }}</p>
                        <p v-else-if="item.domingo.activo == 1">{{ item.domingo.nombre }}</p>
                    </template>
                </v-data-table>

                <br> <br>
                <div>
                    <h2>Desglose de Teachers generados</h2>
                    <v-divider class="my-3"></v-divider>
                    <h4>Teachers por Sexo</h4>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col>
                            Teachers Mujeres : {{teachersPerfectosMujeres}}
                        </v-col>
                        <v-col>
                            Teachers Sexo Indistinto : {{teachersPerfectosTotales - teachersPerfectosMujeres}}
                        </v-col>
                    </v-row>
                    <br>
                    <h4>Teachers por sucursal</h4>
                    <v-divider></v-divider>
                    <v-row class="my-2">
                        <!-- <v-col cols="6" sm="3" md="2" v-for="(plantel, i) in planteles" :key="i" class="py-1">
                            <v-chip @click="dialogPlantel = true, infoPlantel = plantel" >{{ plantel.plantel }} : {{ plantel.teachers }}</v-chip>
                        </v-col> -->

                        <v-sheet class="d-flex flex-wrap bg-surface-variant mt-2">
                            <v-chip class="ma-1" v-for="(plantel, i) in planteles" :key="i" @click="dialogPlantel = true, infoPlantel = plantel" >{{ plantel.plantel }} : {{ plantel.teachers }}</v-chip>
                        </v-sheet>
                    </v-row>
                    
                    <br>
                    <h4>Teachers por modalidad</h4>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col>
                            Teachers Online : {{ teachersPerfectosOnline }}
                        </v-col>
                        <v-col>
                            Teachers Presencial : {{ teachersPerfectosTotales - teachersPerfectosOnline }}
                        </v-col>
                    </v-row>
    
                    <br>
                    <h4>Teachers por horario</h4>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col>
                            Teachers entre semana matutinos : {{ teachersPerfectosMatutino }}
                        </v-col>
                        <v-col>
                            Teachers entre semana vespertinos : {{ teachersPerfectosVespertino }}
                        </v-col>
                        <v-col>
                            Teachers sabatinos : {{ teachersPerfectosSabatinos }}
                        </v-col>
                        <v-col>
                            Teachers dominicales: {{ teachersPerfectosDominicales }}
                        </v-col>
                    </v-row>
                    <br>
                    <v-row justify="end">
                        <v-col cols="6">
                            <h3 class="d-flex justify-end">Teachers totales : {{ teachersPerfectosTotales }}</h3>
                        </v-col>
                    </v-row>
                    <br>
                </div>
                <br>
                <v-row>
                    <v-col align-self="end">
                        <h2>Asignacion de teachers</h2>
                    </v-col>
                    <!-- <v-col class="d-flex justify-end align-end">
                        <v-btn @click="dialogTeachers = true" color="error">Eliminar Asignacion</v-btn>
                    </v-col> -->
                    
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-row>
                    <v-col v-if="consultaConTodasLasReglas">
                        Teachers generados con teacher asignado : {{ teachersPerfectos.filter(el=>el.idTeacherAsignado != 0).length }} <br>
                        Teachers generados sin teacher asignado : {{ teachersPerfectos.filter(el=>el.idTeacherAsignado == 0).length }} <br>
                        Teachers que no se asignaron : {{ teachers.filter(el=>el.idTeacherAsignado == 0).length }} <br>
                    </v-col>
                    <v-col v-else>
                        Es necesario realizar la consulta con todas las reglas activas para utilizar la asignacion manual y prerol.
                    </v-col>
                </v-row>
                
                <br> <br>

                <v-row>
                    <v-col>
                        <h2>Lista de Teachers Generados</h2>
                    </v-col>
                    <v-col class="d-flex justify-end align-end">
                        <span>Total de sueldos / semana: {{ sumSueldos }}</span>
                    </v-col>
                    <v-col class="d-flex justify-end align-end">
                        <span>Total de sueldos / ciclo: {{ sumSueldosCiclo }}</span>
                    </v-col>
                </v-row>
                
                <v-divider class="my-3"></v-divider>
                <v-data-table
                    :headers="teachersHeaders"
                    :items="teachersPerfectos"
                    item-key="idTeacher"
                    show-expand
                    class="elevation-1"
                >
                    <template v-slot:item.sexo="{item}">
                        <span v-if="item.sexo == 'M'">Femenino</span>
                        <span v-else>Indistinto</span>
                    </template>

                    <template v-slot:item.id_plantel_prioridad="{item}">
                        <span v-for="(plantel, i) in planteles" :key="i" v-if="plantel.id_plantel == item.id_plantel_prioridad">{{ plantel.plantel }}</span>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-5">
                        <tr v-for="(grupo, i) in item.grupos" :key="i">
                            {{ grupo.nombre_grupo }}
                        </tr>
                    </td>
                    </template>
                </v-data-table>
                <br>

                <v-row>
                    <v-col class="d-flex justify-end align-end">
                        <v-btn @click="dialogTeachers = true" color="primary" v-if="existeAsignacion && consultaConTodasLasReglas">Siguiente</v-btn>
                        <v-btn @click="guardarJSON" color="primary" v-if="!existeAsignacion && consultaConTodasLasReglas">Siguiente</v-btn>
                    </v-col>
                </v-row>
                <v-snackbar v-model="alertaAsignacion2" color="success">
                    <span class="px-4">Se guardaron los Teachers Generados, puede comenzar a asignar manualmente</span>

                    <template v-slot:action="{ attrs }">
                        <v-btn color="white" text v-bind="attrs" @click="alertaAsignacion2 = false"> X </v-btn>
                    </template>
                </v-snackbar>
                
                <v-dialog v-model="dialogPlantel" max-width="800">
                    <v-card>
                        <v-card-title primary-title> 
                            {{ infoPlantel.plantel }} - Teachers necesarios: {{ infoPlantel.teachers  }}
                            <v-spacer></v-spacer>
                            <v-btn fab small text @click="dialogPlantel = false" ><v-icon>mdi-close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            Teachers por Sexo
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    Teachers Mujeres : {{infoPlantel.teachersMujeres}}
                                </v-col>
                                <v-col>
                                    Teachers Sexo Indistinto : {{infoPlantel.teachers - infoPlantel.teachersMujeres}}
                                </v-col>
                            </v-row>
                            <br>
                            Teachers por horario
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    Teachers entre semana matutinos : {{ infoPlantel.teachersMat }}
                                </v-col>
                                <v-col>
                                    Teachers entre semana vespertinos : {{ infoPlantel.teachersVes }}
                                </v-col>
                                <v-col>
                                    Teachers sabatinos : {{ infoPlantel.teachersS }}
                                </v-col>
                                <v-col>
                                    Teachers dominicales: {{ infoPlantel.teachersD }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogTeachers" max-width="90vw">
                    <v-card>
                        <v-card-title>
                            <span>Asignacion a Teachers Reales</span>
                            <h2></h2> 
                            <v-spacer></v-spacer>
                            <v-btn fab small text @click="dialogTeachers = false" ><v-icon>mdi-close</v-icon></v-btn>
                        </v-card-title>
                        
                        <v-card-text>
                            <v-row>
                                <v-col class="d-flex justify-end align-end">
                                    <h3>
                                        Porcentaje de asignacion: {{porcentajeAsignacionTexto}} %
                                    </h3> 
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-btn color="primary" block @click="dialogReporteVD = true">R. Vacantes Disponibles</v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn color="primary" block @click="dialogReporteTSR = true">R. Teachers sin Rolar</v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn color="primary" block @click="dialogReporteTG = true">R. Teachers Generados</v-btn>
                                </v-col>
                            </v-row>
                            <br>
                            <v-row>
                                <v-col cols="12" sm="6" md="3" class="py-0">
                                    <v-autocomplete filled :items="[{id: 1, texto: 'Prioridad Baja'},{id: 2, texto: 'Prioridad Media'},{id: 3, texto: 'Prioridad Alta'}]"
                                        v-model="prioridadFiltro" item-text="texto" item-value="id" label="Prioridad" clearable dense single-line hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="3" class="py-0">
                                    <v-autocomplete filled :items="planteles"
                                        v-model="plantelFiltro2" item-text="plantel" item-value="id_plantel" label="Plantel" clearable dense single-line hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="3" class="py-0">
                                    <v-autocomplete filled :items="[{id: 1, texto: 'Dominical'},{id: 2, texto: 'Sabatino'},{id: 3, texto: 'Entre semana'}]"
                                        v-model="frecuenciaFiltro2" item-text="texto" item-value="id" label="Frecuencia" clearable dense single-line hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-checkbox label="Vacantes libres" hide-details class="ma-0 pa-0" v-model="vacantesLibres"></v-checkbox>
                                </v-col>
                                <v-col>
                                    <v-checkbox label="Teachers sin rolar" hide-details class="ma-0 pa-0" v-model="teachersSinRolar"></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-text-field filled single-line label="Buscar" append-icon="mdi-magnify" hide-details v-model="buscarAsignacionManual" dense clearable></v-text-field>
                                </v-col>
                            </v-row>
                            <br>
                            <v-data-table :headers="asignacionManual" :items="filterTeachersAsignacion" item-key="id" show-expand class="elevation-1" :search="buscarAsignacionManual" >

                                <template v-slot:item.asignacionPrioridad="{item}">
                                    <v-container v-if="item.teacherPerfecto" class="pa-0">
                                        <v-alert class="ma-0 pa-1" type="success" v-if="item.teacherPerfecto.lock">Asignado</v-alert>
                                        <v-alert class="ma-0 pa-1" type="error" v-if="!item.teacherPerfecto.lock && item.teacherPerfecto.prioridad==3">Prioridad Alta</v-alert>
                                        <v-alert class="ma-0 pa-1" type="info" v-if="!item.teacherPerfecto.lock && item.teacherPerfecto.prioridad==2">Prioridad Media</v-alert>
                                        <v-alert class="ma-0 pa-1" type="warning" v-if="!item.teacherPerfecto.lock && item.teacherPerfecto.prioridad==1">Prioridad Baja</v-alert>
                                    </v-container>
                                </template>

                                <!-- <template v-slot:item.teacherPerfectoNombre="{item}">
                                    <h4 v-if="item.teacherPerfecto" class="px-2">{{ item.teacherPerfecto.idTeacher }} - {{ item.teacherPerfecto.nombre }} - Plantel: 
                                        {{ planteles.find(el=>el.id_plantel == item.teacherPerfecto.id_plantel_prioridad).plantel }}  
                                        <span v-if="item.teacherPerfecto.sexo == 'M'"> - Teacher Kids/Teens</span> 
                                    </h4>
                                    <span v-else>No se encontro match</span>
                                </template> -->

                                <!-- <template v-slot:item.teacherRealNombre="{item}">
                                    <h4 v-if="item.teacherReal" class="px-2"> {{ item.teacherReal.id_usuario }} - {{ item.teacherReal.nombre_completo }} - Plantel:
                                        <span v-if="item.teacherReal.id_plantel">
                                            {{ planteles.find(el=>el.id_plantel == item.teacherReal.id_plantel).plantel }}
                                        </span>
                                    </h4>
                                    <span v-else>Vacante</span>
                                </template> -->

                                <template v-slot:expanded-item="{item }">
                                    <td :colspan="asignacionManual.length + 1" class="py-5" style="vertical-align:top">
                                        <v-row>
                                            <v-col cols="3">
                                                <v-container v-if="item.teacherReal && item.teacherPerfecto" class="pa-0">
                                                    <v-btn @click="actualizarJSONLock(item, true)" v-if="!item.teacherPerfecto.lock" color="primary">Guardar Seleccion</v-btn>
                                                    
                                                    <v-btn @click="actualizarJSONLock(item, false)" v-if="item.teacherPerfecto.lock && !item.teacherPerfecto.aceptadoXTeacher" color="error">Eliminar Seleccion</v-btn>
                                                    <span v-if="item.teacherPerfecto.aceptadoXTeacher">Aceptado por el teacher</span>
                                                </v-container>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-container v-if="item.teacherPerfecto" class="pa-0">
                                                    <v-container v-if="item.teacherPerfecto.grupos" class="pa-0">
                                                        <v-container v-if="!item.teacherPerfecto.lock" class="pa-0">
                                                            <h4>Prioridad: </h4>
                                                            <v-radio-group  v-model="item.teacherPerfecto.prioridad" @change="actualizarJSONPrioridad(item)" row class="ma-0" hide-details>
                                                                <v-radio :value="3" label="Alta"></v-radio>
                                                                <v-radio :value="2" label="Media"></v-radio>
                                                                <v-radio :value="1" label="Baja"></v-radio>
                                                            </v-radio-group>
                                                        </v-container>
                                                        <v-row>
                                                            <v-col class="py-5">
                                                                <h4>Horas:</h4>
                                                                <v-icon>mdi-circle-small</v-icon><span>Entre semana: {{ item.teacherPerfecto.LVhoras }}</span> <br>
                                                                <v-icon>mdi-circle-small</v-icon><span>Sabado: {{ item.teacherPerfecto.Shoras }}</span> <br>
                                                                <v-icon>mdi-circle-small</v-icon><span>Domingo: {{ item.teacherPerfecto.Dhoras }}</span> <br>
                                                            </v-col>
                                                            
                                                            <v-col class="py-5">
                                                                <h4>Sueldo:</h4>
                                                                <v-icon>mdi-circle-small</v-icon><span>Semanal: {{ item.teacherPerfecto.sueldo }}</span> <br>
                                                                <v-icon>mdi-circle-small</v-icon><span>Ciclo: {{ item.teacherPerfecto.sueldo * 4 }}</span> <br>
                                                            </v-col>
                                                        </v-row>
                                                        <h4>Grupos : </h4>
                                                        <span v-for="(grupo, k) in item.teacherPerfecto.grupos"  :key="k">
                                                            <v-icon>mdi-circle-small</v-icon> {{ grupo.nombre_grupo }}<br>
                                                        </span>
                                                    </v-container>
                                                </v-container>
                                            </v-col>
                                            <v-col>
                                                <v-container v-if="item.teacherReal" class="pa-0">
                                                    <v-row>
                                                        <v-col>
                                                            <div>
                                                                <v-row class="mb-1">
                                                                    <v-col>
                                                                        <h4>Planteles: </h4>
                                                                        <div v-if="item.teacherReal.planteles.length"> 
                                                                            <span v-for="(plantel, k) in item.teacherReal.planteles" :key="k">prioridad: {{plantel.prioridad}} - {{ planteles.find(el=>el.id_plantel == plantel.idplantel).plantel }}<br></span>
                                                                        </div>
                                                                        <div v-else>
                                                                            No se encontraron planteles asignados
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <h4>Niveles: </h4>
                                                                        <span v-if="item.teacherReal.niveles">
                                                                            <v-row>
                                                                                <v-col cols="6">
                                                                                    <span> INBI KIDS :</span> <br>
                                                                                    <span v-for="(niv, j) in item.teacherReal.nivelesAcomodados.kidsINBI" :key="j"> 
                                                                                        <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                                    </span>
                                                                                </v-col>
                                                                                <v-col cols="6">
                                                                                    <span> INBI TEENS :</span> <br>
                                                                                    <span v-for="(niv, j) in item.teacherReal.nivelesAcomodados.teensINBI" :key="j"> 
                                                                                        <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                                    </span>
                                                                                </v-col>
                                                                                <v-col cols="6">
                                                                                    <span> INBI ADULTOS :</span> <br>
                                                                                    <span v-for="(niv, j) in item.teacherReal.nivelesAcomodados.adultosINBI" :key="j"> 
                                                                                        <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                                    </span>
                                                                                </v-col>
                                                                                <v-col cols="6">
                                                                                    <span> FAST ADULTOS :</span> <br>
                                                                                    <span v-for="(niv, j) in item.teacherReal.nivelesAcomodados.adultosFAST" :key="j"> 
                                                                                        <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                                    </span>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </span>
                                                                        <span v-else>
                                                                            No se encontraron niveles asignados
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                            <div>
                                                                <h4>Disponibilidad: </h4>
                                                                <div v-if="item.teacherReal.disponibilidad.length">  
                                                                    <span v-for="(disp, j) in item.teacherReal.disponibilidad" :key="j"> 
                                                                        <v-icon>mdi-circle-small</v-icon> dia: {{disp.dia}} -  horario: {{disp.inicio}} - {{disp.fin}}<br>
                                                                    </span>
                                                                </div>
                                                                <div v-else> No se encontro disponibilidad asignada </div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.teacherPerfecto">
                                            <v-col v-if="item.teacherPerfecto.TeachersFiltrados.length">
                                                Todos los teachers disponibles: {{ item.teacherPerfecto.TeachersFiltrados.length }} <br>
                                                <v-chip v-for="(teacherPosible, i) in item.teacherPerfecto.TeachersFiltrados" :key="i" class="ma-1" @click="asignarTeacher(item, teacherPosible)">
                                                    {{ teacherPosible.idTeacherAsignado }} - {{ teachers.find(el=>el.id_usuario == teacherPosible.idTeacherAsignado).nombre_completo }} - {{ teacherPosible.porcentaje }}% <br>
                                                </v-chip>
                                            </v-col>
                                            <!-- <v-col v-if="item.teacherPerfecto.Teachers.length">
                                                Todos los teachers disponibles: {{ item.teacherPerfecto.Teachers.length }} <br>
                                                <v-chip v-for="(teacherPosible, i) in item.teacherPerfecto.Teachers" :key="i" class="ma-1" @click="asignarTeacher(item, teacherPosible)">
                                                    {{ teacherPosible.idTeacherAsignado }} - {{ teachers.find(el=>el.id_usuario == teacherPosible.idTeacherAsignado).nombre_completo }} - {{ teacherPosible.porcentaje }}% <br>
                                                </v-chip>
                                            </v-col> -->
                                        </v-row>
                                    </td>
                                </template>
                            </v-data-table>
                            
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <span v-if="porcentajeAsignacion != 100" class="me-2">Para poder continuar es necesario tener el 100% de asignacion</span>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <!-- agregar a boton para ocultarlo si no esta el 100% de asignacion: v-if="porcentajeAsignacion == 100" -->
                                    <v-btn color="primary" @click="mostrarPreRol"  dark v-bind="attrs" v-on="on"> Siguiente </v-btn>
                                </template>
                                <span>Continuar con pre-rol</span>
                            </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                
                <v-snackbar v-model="alertaAsignacion3" color="success">
                    <span class="px-4">Se libero el rol, los teachers pueden comenzar a aceptar sus roles</span>

                    <template v-slot:action="{ attrs }">
                        <v-btn color="white" text v-bind="attrs" @click="alertaAsignacion3 = false"> X </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar v-model="alertaAsignacion" color="error">
                    <span class="px-4">No es posible realizar ese cambio</span>

                    <template v-slot:action="{ attrs }">
                        <v-btn color="white" text v-bind="attrs" @click="alertaAsignacion = false"> X </v-btn>
                    </template>
                </v-snackbar>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogReporteVD" max-width="90vw">
                    <v-card>
                        <v-card-title>
                            Reporte de Vacantes Disponibles
                            <v-spacer></v-spacer>
                            <v-btn fab small text @click="dialogReporteVD = false" ><v-icon>mdi-close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            <h4>Teachers por Sexo</h4>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    Teachers Mujeres : {{teachersVacantesReporte.mujeres}}
                                </v-col>
                                <v-col>
                                    Teachers Sexo Indistinto : {{teachersVacantesReporte.vacantes.length - teachersVacantesReporte.mujeres}}
                                </v-col>
                            </v-row>
                            <br>
                            <h4>Teachers por sucursal</h4>
                            <v-divider></v-divider>
                            <v-row class="my-2">
                                <v-sheet class="d-flex flex-wrap bg-surface-variant mt-2" v-if="teachersVacantesReporte.planteles.length">
                                    <v-chip class="ma-1" v-for="(plantel, i) in teachersVacantesReporte.planteles" :key="i" @click="dialogPlantel = true, infoPlantel = plantel" >{{ plantel.plantel }} : {{ plantel.teachers }}</v-chip>
                                </v-sheet>
                                <v-col v-else>No se encontro la informacion</v-col>
                            </v-row>
                            <br>
                            <h4>Teachers por modalidad</h4>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    Teachers Online : {{ teachersVacantesReporte.online }}
                                </v-col>
                                <v-col>
                                    Teachers Presencial : {{ teachersVacantesReporte.vacantes.length - teachersVacantesReporte.online }}
                                </v-col>
                            </v-row>
            
                            <br>
                            <h4>Teachers por horario</h4>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    Teachers entre semana matutinos : {{ teachersVacantesReporte.horarioLVMAt }}
                                </v-col>
                                <v-col>
                                    Teachers entre semana vespertinos : {{ teachersVacantesReporte.horarioLVVes }}
                                </v-col>
                                <v-col>
                                    Teachers sabatinos : {{ teachersVacantesReporte.horarioSab }}
                                </v-col>
                                <v-col>
                                    Teachers dominicales: {{ teachersVacantesReporte.horarioDom }}
                                </v-col>
                            </v-row>
                            <br>
                            <v-row justify="end">
                                <v-col cols="6">
                                    <h3 class="d-flex justify-end">Total de teachers generados vacantes: {{ teachersVacantesReporte.vacantes.length }}</h3>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
        </v-dialog>
        <v-dialog v-model="dialogReporteTSR" max-width="90vw">
            <v-card>
                <v-card-title>
                    Reporte de Teachers sin Rolar
                    <v-spacer></v-spacer>
                    <v-btn fab small text @click="dialogReporteTSR = false" ><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field filled single-line label="Buscar" append-icon="mdi-magnify" hide-details v-model="buscarTSinRolar" dense ></v-text-field>
                        </v-col>
                    </v-row>
                    <br>
                    <v-data-table :headers="teachersSinRolarHeaders" :items="teachersSR" item-key="id_usuario" show-expand class="elevation-1" :search="buscarTSinRolar">
                        <template v-slot:item.info="{item}">
                            <span>
                                <v-chip :color="item.niveles.length ? 'success' : 'error'"> Niveles </v-chip> 
                                <v-chip :color="item.planteles.length ? 'success' : 'error'"> Planteles </v-chip> 
                                <v-chip :color="item.disponibilidad.length ? 'success' : 'error'"> Disponibilidad </v-chip>
                            </span>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                            <v-container class="pa-0">
                                <v-row>
                                    <v-col>
                                        <div>
                                            <v-row class="mb-1">
                                                <v-col>
                                                    <h4>Planteles: </h4>
                                                    <div v-if="item.planteles.length"> 
                                                        <span v-for="(plantel, k) in item.planteles" :key="k">prioridad: {{plantel.prioridad}} - {{ planteles.find(el=>el.id_plantel == plantel.idplantel).plantel }}<br></span>
                                                    </div>
                                                    <div v-else>
                                                        No se encontraron planteles asignados
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <h4>Niveles: </h4>
                                                    <span v-if="item.niveles">
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <span> INBI KIDS :</span> <br>
                                                                <span v-for="(niv, j) in item.nivelesAcomodados.kidsINBI" :key="j"> 
                                                                    <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                </span>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <span> INBI TEENS :</span> <br>
                                                                <span v-for="(niv, j) in item.nivelesAcomodados.teensINBI" :key="j"> 
                                                                    <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                </span>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <span> INBI ADULTOS :</span> <br>
                                                                <span v-for="(niv, j) in item.nivelesAcomodados.adultosINBI" :key="j"> 
                                                                    <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                </span>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <span> FAST ADULTOS :</span> <br>
                                                                <span v-for="(niv, j) in item.nivelesAcomodados.adultosFAST" :key="j"> 
                                                                    <v-icon>mdi-circle-small</v-icon> {{niv.nombrenivel}}<br>
                                                                </span>
                                                            </v-col>
                                                        </v-row>
                                                    </span>
                                                    <span v-else>
                                                        No se encontraron niveles asignados
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div>
                                            <h4>Disponibilidad: </h4>
                                            <div v-if="item.disponibilidad.length">  
                                                <span v-for="(disp, j) in item.disponibilidad" :key="j"> 
                                                    <v-icon>mdi-circle-small</v-icon> dia: {{disp.dia}} -  horario: {{disp.inicio}} - {{disp.fin}}<br>
                                                </span>
                                            </div>
                                            <div v-else> No se encontro disponibilidad asignada </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogReporteTG" max-width="50vw">
            <v-card>
                <v-card-title>
                    Reporte de Teachers Generados
                    <v-spacer></v-spacer>
                    <v-btn fab small text @click="dialogReporteTG = false" ><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                
                <v-card-text>
                    <v-row>
                        <v-col>
                            Teachers Generados = {{ teachersPerfectos.length }}<br>
                            <v-divider></v-divider>
                            Teachers Generados Asignados = {{ teachersPerfectos.filter(el=>el.idTeacherAsignado!=0).length }}<br>
                            Teachers Generados Sin Asignar = {{ teachersPerfectos.filter(el=>el.idTeacherAsignado==0).length }}<br>
                        </v-col>
                        <v-col style="margin-top: -30px;" class="d-flex justify-center">
                            <v-progress-circular :value="porcentajeAsignacion" color="primary" :width="15" :size="100"> {{ porcentajeAsignacionTexto }}% </v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPreRol" max-width="90vw">
            <v-card>
                <v-card-title>
                    <span class="me-4"> Pre-rol </span>
                    <v-spacer></v-spacer>
                    <v-btn fab small text @click="dialogPreRol = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn color="primary" @click="dialogAceptacion = true">Lista de aceptacion</v-btn>
                        </v-col>
                        <v-col>
                            <h3>Porcentaje de aceptacion: {{ porcentajeAceptacion }} %</h3>
                        </v-col>
                        <v-col class="d-flex justify-end align-end">
                            <v-autocomplete filled :items="[{id: 1, texto: 'Aceptados'},{id: 2, texto: 'Rechazados'},{id: 3, texto: 'Pendientes'}]"
                                v-model="estatusFiltroPreRol" item-text="texto" item-value="id" label="Estatus" clearable dense single-line hide-details
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="6" lg="4">
                            <v-radio-group v-model="escuelaFiltro3" row hide-details class="my-2">
                                <v-radio label="Ambos" :value="0"></v-radio>
                                <v-radio label="INBI" :value="1"></v-radio>
                                <v-radio label="FAST" :value="2"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row class="mb-1">
                        <v-col cols="12" sm="6" md="3" class="py-0">
                            <v-autocomplete filled :items="[{id: 1, texto: 'Dominical'},{id: 2, texto: 'Sabatino'},{id: 3, texto: 'Entre semana'}]"
                                v-model="frecuenciaFiltro3" item-text="texto" item-value="id" label="Frecuencia" clearable dense single-line hide-details
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="py-0">
                            <v-autocomplete filled :items="niveles"
                                v-model="nivelFiltro3" item-text="texto" item-value="id" label="Nivel" clearable dense single-line hide-details
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="py-0">
                            <v-autocomplete filled :items="planteles"
                                v-model="plantelFiltro3" item-text="plantel" item-value="id_plantel" label="Plantel" clearable dense single-line hide-details
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="py-0">
                            <v-autocomplete filled :items="horarios"
                                v-model="horarioFiltro3" item-text="texto" item-value="id" label="Horario" clearable dense single-line hide-details
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col>
                            <v-data-table
                                :headers="gruposHeaders"
                                :items="filterGruposPreRol"
                                item-key="nombre_grupo"
                                class="elevation-1 mb-2"
                            >
                                <template v-slot:item.hora_inicio="{item}">
                                    {{item.hora_inicio}} - {{ item.hora_fin }}
                                </template>
        
                                <template v-slot:item.lu="{item}">
                                    <p class="mb-0" v-if="item.lunes.activo == 1 && item.lunes.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)"> Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.lunes.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.lunes.teacher)"> 
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.lunes.teacherReal" :color="item.lunes.color"> {{ item.lunes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.lunes.color"> {{ item.lunes.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.sabado.activo == 1 && item.lunes.teacher == 0 && item.sabado.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1 && item.lunes.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.lunes.teacher)"> 
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.lunes.teacherReal" :color="item.lunes.color"> {{ item.lunes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.lunes.color"> {{ item.lunes.teacherReal }} </v-alert>  
                                    </p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.sabado.teacher)">  
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.sabado.teacherReal" :color="item.sabado.color"> {{ item.sabado.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.sabado.color"> {{ item.sabado.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.domingo.activo == 1 && item.lunes.teacher == 0 && item.domingo.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1 && item.lunes.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.lunes.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.lunes.teacherReal" :color="item.lunes.color"> {{ item.lunes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.lunes.color"> {{ item.lunes.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.domingo.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.domingo.teacherReal" :color="item.domingo.color"> {{ item.domingo.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.domingo.color"> {{ item.domingo.teacherReal }} </v-alert>
                                    </p>
                                </template>
                                <template v-slot:item.ma="{item}">
                                    <p class="mb-0" v-if="item.martes.activo == 1 && item.martes.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.martes.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.martes.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.martes.teacherReal" :color="item.martes.color"> {{ item.martes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.martes.color"> {{ item.martes.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.sabado.activo == 1 && item.martes.teacher == 0 && item.sabado.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1 && item.martes.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.martes.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.martes.teacherReal" :color="item.martes.color"> {{ item.martes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.martes.color"> {{ item.martes.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.sabado.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.sabado.teacherReal" :color="item.sabado.color"> {{ item.sabado.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.sabado.color"> {{ item.sabado.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.domingo.activo == 1 && item.martes.teacher == 0 && item.domingo.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1 && item.martes.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.martes.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.martes.teacherReal" :color="item.martes.color"> {{ item.martes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.martes.color"> {{ item.martes.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.domingo.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.domingo.teacherReal" :color="item.domingo.color"> {{ item.domingo.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.domingo.color"> {{ item.domingo.teacherReal }} </v-alert>
                                    </p>
                                </template>
                                <template v-slot:item.mi="{item}">
                                    <p class="mb-0" v-if="item.miercoles.activo == 1 && item.miercoles.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.miercoles.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.miercoles.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.miercoles.teacherReal" :color="item.miercoles.color"> {{ item.miercoles.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.miercoles.color"> {{ item.miercoles.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.sabado.activo == 1 && item.miercoles.teacher == 0 && item.sabado.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1 && item.miercoles.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.miercoles.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.miercoles.teacherReal" :color="item.miercoles.color"> {{ item.miercoles.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.miercoles.color"> {{ item.miercoles.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.sabado.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.sabado.teacherReal" :color="item.sabado.color"> {{ item.sabado.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.sabado.color"> {{ item.sabado.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.domingo.activo == 1 && item.miercoles.teacher == 0 && item.domingo.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1 && item.miercoles.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.miercoles.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.miercoles.teacherReal" :color="item.miercoles.color"> {{ item.miercoles.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.miercoles.color"> {{ item.miercoles.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.domingo.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.domingo.teacherReal" :color="item.domingo.color"> {{ item.domingo.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.domingo.color"> {{ item.domingo.teacherReal }} </v-alert>
                                    </p>
                                </template>
                                <template v-slot:item.ju="{item}">
                                    <p class="mb-0" v-if="item.jueves.activo == 1 && item.jueves.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.jueves.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.jueves.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.jueves.teacherReal" :color="item.jueves.color"> {{ item.jueves.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.jueves.color"> {{ item.jueves.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.sabado.activo == 1 && item.jueves.teacher == 0 && item.sabado.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1 && item.jueves.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.jueves.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.jueves.teacherReal" :color="item.jueves.color"> {{ item.jueves.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.jueves.color"> {{ item.jueves.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.sabado.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.sabado.teacherReal" :color="item.sabado.color"> {{ item.sabado.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.sabado.color"> {{ item.sabado.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.domingo.activo == 1 && item.jueves.teacher == 0 && item.domingo.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1 && item.jueves.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.jueves.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.jueves.teacherReal" :color="item.jueves.color"> {{ item.jueves.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.jueves.color"> {{ item.jueves.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.domingo.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.domingo.teacherReal" :color="item.domingo.color"> {{ item.domingo.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.domingo.color"> {{ item.domingo.teacherReal }} </v-alert>
                                    </p>
                                </template>
                                <template v-slot:item.vi="{item}">
                                    <p class="mb-0" v-if="item.viernes.activo == 1 && item.viernes.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.viernes.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.viernes.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.viernes.teacherReal" :color="item.viernes.color"> {{ item.viernes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.viernes.color"> {{ item.viernes.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.sabado.activo == 1 && item.viernes.teacher == 0 && item.sabado.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1 && item.viernes.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.viernes.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.viernes.teacherReal" :color="item.viernes.color"> {{ item.viernes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.viernes.color"> {{ item.viernes.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.sabado.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.sabado.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.sabado.teacherReal" :color="item.sabado.color"> {{ item.sabado.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.sabado.color"> {{ item.sabado.teacherReal }} </v-alert>
                                    </p>

                                    <p class="mb-0" v-if="item.domingo.activo == 1 && item.viernes.teacher == 0 && item.domingo.teacher == 0" @click="mostrarDialogReemplazarTeacher(item, 0)">Teacher sin asignar</p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1 && item.viernes.teacher != 0" @click="mostrarDialogReemplazarTeacher(item, item.viernes.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.viernes.teacherReal" :color="item.viernes.color"> {{ item.viernes.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.viernes.color"> {{ item.viernes.teacherReal }} </v-alert>
                                    </p>
                                    <p class="mb-0" v-else-if="item.domingo.activo == 1" @click="mostrarDialogReemplazarTeacher(item, item.domingo.teacher)">
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-if="!item.domingo.teacherReal" :color="item.domingo.color"> {{ item.domingo.nombre }} </v-alert>
                                        <v-alert class="ma-0 pa-1" style="font-size: 14px;" text dense v-else :color="item.domingo.color"> {{ item.domingo.teacherReal }} </v-alert>
                                    </p>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarGenerarRol = true" v-if="rolLiberado && !rolGenerado">Siguiente</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogReemplazarTeacher" max-width="50vw">
            <v-card>
                <v-card-title>
                    Asignar reemplazo
                    <v-spacer></v-spacer>
                    <v-btn fab small text @click="dialogReemplazarTeacher = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-alert color="primary" border="left" colored-border>
                        Asignar un reemplazo cambiara al teacher actual por el seleccionado en el bloque de clases, es decir, lunes a miercoles o jueves y viernes
                    </v-alert>
                    <p>Teacher actual:</p>
                    <p> ROL: {{ teacherAReemplazar.nombre }} - 
                        TEACHER :{{ teachers.find(el=>el.id_usuario == teacherAReemplazar.idTeacherAsignado) 
                        ? teachers.find(el=>el.id_usuario == teacherAReemplazar.idTeacherAsignado).nombre_completo : '' }}
                    </p>
                    <p>Paquete a reemplazar:</p>
                    <p v-for="(grupo, i) in teacherAReemplazar.grupos" :key="i">{{grupo.nombre_grupo}}</p>
                    <p>Teacher nuevo:</p>
                    <v-autocomplete filled dense clearable v-model="teacherReemplazo" :items="posiblesReemplazos" label="Selecciona un teacher" persistent-hint
                        single-line hide-details return-object item-text="nombre_completo" item-value="idTeacher">
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="dialogReemplazarTeacher = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="confirmarReemplazarTeacher">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAceptacion" max-width="50vw">
            <v-card>
                <v-card-title>
                    Aceptacion de rol
                    <v-spacer></v-spacer>
                    <v-btn fab small text @click="dialogAceptacion = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="d-flex justify-end align-end">
                            <v-autocomplete filled :items="[{id: 1, texto: 'Aceptados'},{id: 2, texto: 'Rechazados'},{id: 3, texto: 'Pendientes'}]"
                                v-model="estatusFiltroLista" item-text="texto" item-value="id" label="Estatus" clearable dense single-line hide-details
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <br>
                    <v-data-table
                        :headers="aceptacionTeachers"
                        :items="filterTeachersAceptacion"
                        item-key="idTeacherAsignado"
                        class="elevation-1 mb-2"
                    >

                        <template v-slot:item.nombre_completo="{item}">
                            {{ teachers.find(el=>item.idTeacherAsignado == el.id_usuario) ? teachers.find(el=>item.idTeacherAsignado == el.id_usuario).nombre_completo : '' }}
                        </template>
                        <template v-slot:item.estatus="{item}">
                            <v-alert class="ma-0 pa-1" type="info" color="grey" v-if="item.aceptadoXTeacher == 0">Pendiente</v-alert>
                            <v-alert class="ma-0 pa-1" type="success" v-if="item.aceptadoXTeacher == 1">Aceptado</v-alert>
                            <v-alert class="ma-0 pa-1" type="error" v-if="item.aceptadoXTeacher == 2">Rechazado</v-alert>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="confirmarLiberacionPreRol" max-width="50vw">
        <v-card>
            <v-card-title>
                Confirmar Pre-Rol
            </v-card-title>
            <v-card-text>
                Despues de confirmar el rol no hay cambios.
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="confirmarLiberacionPreRol = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="liberarRol">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="confirmarGenerarRol" max-width="50vw">
        <v-card>
            <v-card-title>
                Confirmar Rol
            </v-card-title>
            <v-card-text>
                Esta accion genera el rol final
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="confirmarGenerarRol = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="generarRol">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        
        <!-- Dialog de carga -->
        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>
    </v-container>
</template>

<script>
    import axios from 'axios';
	import { mapGetters } from 'vuex'

    import Alerta                from '@/components/alertas/Alerta.vue'
    import carga                 from '@/components/alertas/carga.vue';

    import validarErrores        from '@/mixins/validarErrores'

    var moment = require('moment');
    moment.locale(); 

    export default {
        components:{
            Alerta,
            carga,
        },

        mixins: [ validarErrores ],

        data: () => ({
            dialogReporteVD: false,
            dialogReporteTSR: false,
            dialogReporteTG: false,
            dialogPreRol:false,
            dialogReemplazarTeacher:false,
            alertaAsignacion:false,
            alertaAsignacion2:false,
            alertaAsignacion3:false,
            dialogPlantel : false,
            dialogTeachers: false,
            dialogAceptacion: false,
            confirmarLiberacionPreRol: false,
            confirmarGenerarRol: false,
            infoPlantel : '',
            buscarTSinRolar:'',
            buscarAsignacionManual:'',

            escuela: 0,
            frecuencia: null,
            nivel:null,
            horario:null,
            plantel:null,
            plantelFiltro2:null,
            prioridadFiltro:null,
            frecuenciaFiltro2: null,
            frecuenciaFiltro3: null,
            escuelaFiltro3: null,
            nivelFiltro3:null,
            horarioFiltro3:null,
            plantelFiltro3:null,
            vacantesLibres: false,
            teachersSinRolar: false,

            niveles: [],
            horarios: [],

            teacherReemplazo:null,
            posiblesReemplazos:[],

            estatusFiltroLista:0,
            estatusFiltroPreRol:0,

            cargar:false,
            ciclos:[],
            ciclo:null,
            grupos:[],
            gruposFiltros:[],
            gruposFiltros3:[],
            planteles:[],
            teachersPerfectos:[],
            teachers:[],
            teachersSR:[],
            gruposHeaders:[
                { text: 'ID'                      , value: 'iderp'       },
                { text: 'Nombre'                  , value: 'nombre_grupo'},
                { text: 'Horario'                 , value: 'hora_inicio' },
                { text: 'Lunes'                   , value: 'lu'          },
                { text: 'Martes'                  , value: 'ma'          },
                { text: 'Miercoles'               , value: 'mi'          },
                { text: 'Jueves'                  , value: 'ju'          },
                { text: 'Viernes'                 , value: 'vi'          },
            ],
            teachersHeaders:[
                { text: 'ID'                      , value: 'idTeacher'            },
                { text: 'Nombre'                  , value: 'nombre'               },
                { text: 'Plantel'                 , value: 'id_plantel_prioridad' },
                { text: 'Sexo'                    , value: 'sexo'                 },
                { text: 'Total de Grupos'         , value: 'totalgrupos'          },
                { text: 'Horas L-V'               , value: 'LVhoras'              },
                { text: 'Horas S'                 , value: 'Shoras'               },
                { text: 'Horas D'                 , value: 'Dhoras'               },
                { text: 'Total de Horas/Semana'   , value: 'totalhoras'           },
                { text: 'Total de Horas/Ciclo'    , value: 'totalhorasciclo'      },
                { text: 'Sueldo/Semana'           , value: 'sueldo'               },
            ],
            teachersSinRolarHeaders:[
                { text: 'ID'                      , value: 'id_usuario'           },
                { text: 'Nombre'                  , value: 'nombre_completo'      },
                { text: 'Informacion'             , value: 'info'                 },
            ],
            vacantesDisponiblesHeaders:[
                { text: 'Plantel'                 , value: 'plantel'              },
                { text: 'Cantidad'                , value: 'teachers'             },
                { text: 'Mujeres'                 , value: 'teachersMujeres'      },
                { text: 'Sexo Indistinto'         , value: 'teachersIndistinto'   },
                { text: 'Entre semana matutino'   , value: 'teachersMat'   },
                { text: 'Entre semana vespertino' , value: 'teachersVes'   },
                { text: 'Sabado'                  , value: 'teachersS'   },
                { text: 'Domingo'                 , value: 'teachersD'   },
            ],
            asignacionManual:[
                { text: 'Asignacion'              , value: 'asignacionPrioridad'  },
                { text: 'Teacher General'         , value: 'teacherPerfectoNombre'},
                { text: 'Teacher Asignado'        , value: 'teacherRealNombre'    },
            ],
            aceptacionTeachers:[
                { text: 'ID'                      , value: 'idTeacherAsignado'    },
                { text: 'Nombre'                  , value: 'nombre_completo'      },
                { text: 'Informacion'             , value: 'estatus'              },
            ],

            reglasTodas: true,
            reglaHoras : true,
            reglaPlanteles : true,
            reglaTeens : true,
            reglaDias : true,
            reglaTurno : true,
            reglaFrec : true,
            reglaContinuidad: true,

            teachersPerfectosMujeres: 0,
            teachersPerfectosOnline:0,
            teachersPerfectosTotales: 0,
            teachersPerfectosSabatinos : 0,
            teachersPerfectosDominicales : 0,
            teachersPerfectosMatutino : 0,
            teachersPerfectosVespertino : 0,

            sumSueldos : 0,
            sumSueldosCiclo : 0,
            teachersAsignados: [],
            teachersAsignadosFiltrados: [],
            teachersVacantesReporte:{
                vacantes : [],
                mujeres : 0,
                planteles : [],
                online : 0,
                horarioLVMAt: 0,
                horarioLVVes: 0,
                horarioSab:0,
                horarioDom:0,
            },
            teachersSinRolarReporte:{},
            porcentajeAsignacion: 0,
            porcentajeAsignacionTexto: 0,
            porcentajeAceptacion: 0,
            existeAsignacion:false,
            consultaConTodasLasReglas :false,

            teacherAReemplazar:{},
            grupoAReemplazar:{},
            rolLiberado: false,
            rolGenerado: false,
        }),

        computed:{
        ...mapGetters('login',['getdatosUsuario']),

            filterGrupos(){
                const grupos = this.grupos
                if(grupos){
                    let newgrupos = this.grupos;
                    if(this.escuela){
                        if(this.escuela == 0){
                            newgrupos = newgrupos
                        }
                        if(this.escuela == 1){
                            newgrupos = newgrupos.filter(el => !el.nombre_grupo.toLowerCase().includes('fast'))
                        }
                        if(this.escuela == 2){
                            newgrupos = newgrupos.filter(el => el.nombre_grupo.toLowerCase().includes('fast'))
                        }
                    }
                    if(this.frecuencia){
                        if(this.frecuencia == 0){
                            newgrupos = newgrupos
                        }
                        if(this.frecuencia == 1){
                            newgrupos = newgrupos.filter(el => el.domingo.activo)
                        }
                        if(this.frecuencia == 2){
                            newgrupos = newgrupos.filter(el => el.sabado.activo)
                        }
                        if(this.frecuencia == 3){
                            newgrupos = newgrupos.filter(el => !el.domingo.activo && !el.sabado.activo)
                        }
                    }
                    if(this.nivel){
                        newgrupos = newgrupos.filter(el => el.nivel == this.nivel)
                    }
                    if(this.plantel){
                        newgrupos = newgrupos.filter(el => el.id_plantel == this.plantel)
                    }
                    if(this.horario){
                        newgrupos = newgrupos.filter(el => el.hora_inicio == this.horario)
                    }
                    this.gruposFiltros = newgrupos

                    return newgrupos
                }
            },
            filterGruposPreRol(){
                const grupos = this.grupos
                if(grupos){
                    let newgrupos = this.grupos;
                    if(this.escuelaFiltro3){
                        if(this.escuelaFiltro3 == 0){
                            newgrupos = newgrupos
                        }
                        if(this.escuelaFiltro3 == 1){
                            newgrupos = newgrupos.filter(el => !el.nombre_grupo.toLowerCase().includes('fast'))
                        }
                        if(this.escuelaFiltro3 == 2){
                            newgrupos = newgrupos.filter(el => el.nombre_grupo.toLowerCase().includes('fast'))
                        }
                    }
                    if(this.frecuenciaFiltro3){
                        if(this.frecuenciaFiltro3 == 0){
                            newgrupos = newgrupos
                        }
                        if(this.frecuenciaFiltro3 == 1){
                            newgrupos = newgrupos.filter(el => el.domingo.activo)
                        }
                        if(this.frecuenciaFiltro3 == 2){
                            newgrupos = newgrupos.filter(el => el.sabado.activo)
                        }
                        if(this.frecuenciaFiltro3 == 3){
                            newgrupos = newgrupos.filter(el => !el.domingo.activo && !el.sabado.activo)
                        }
                    }
                    if(this.nivelFiltro3){
                        newgrupos = newgrupos.filter(el => el.nivel == this.nivelFiltro3)
                    }
                    if(this.plantelFiltro3){
                        newgrupos = newgrupos.filter(el => el.id_plantel == this.plantelFiltro3)
                    }
                    if(this.horarioFiltro3){
                        newgrupos = newgrupos.filter(el => el.hora_inicio == this.horarioFiltro3)
                    }

                    if(this.estatusFiltroPreRol){
                        if(this.estatusFiltroPreRol == 0){
                            newgrupos = newgrupos
                        }
                        if(this.estatusFiltroPreRol == 1){
                            newgrupos = newgrupos.filter(grupo => {
                                if(this.teachersPerfectos.find(el=>grupo.lunes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.lunes.teacher == el.idTeacher).aceptadoXTeacher == 1){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.martes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.martes.teacher == el.idTeacher).aceptadoXTeacher == 1){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.miercoles.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.miercoles.teacher == el.idTeacher).aceptadoXTeacher == 1){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.jueves.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.jueves.teacher == el.idTeacher).aceptadoXTeacher == 1){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.viernes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.viernes.teacher == el.idTeacher).aceptadoXTeacher == 1){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.sabado.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.sabado.teacher == el.idTeacher).aceptadoXTeacher == 1){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.domingo.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.domingo.teacher == el.idTeacher).aceptadoXTeacher == 1){
                                        return true;
                                    }
                                }
                            })
                        }
                        if(this.estatusFiltroPreRol == 2){
                            newgrupos = newgrupos.filter(grupo => {
                                if(this.teachersPerfectos.find(el=>grupo.lunes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.lunes.teacher == el.idTeacher).aceptadoXTeacher == 2){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.martes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.martes.teacher == el.idTeacher).aceptadoXTeacher == 2){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.miercoles.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.miercoles.teacher == el.idTeacher).aceptadoXTeacher == 2){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.jueves.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.jueves.teacher == el.idTeacher).aceptadoXTeacher == 2){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.viernes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.viernes.teacher == el.idTeacher).aceptadoXTeacher == 2){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.sabado.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.sabado.teacher == el.idTeacher).aceptadoXTeacher == 2){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.domingo.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.domingo.teacher == el.idTeacher).aceptadoXTeacher == 2){
                                        return true;
                                    }
                                }
                            })
                        }
                        if(this.estatusFiltroPreRol == 3){
                            newgrupos = newgrupos.filter(grupo => {
                                if(this.teachersPerfectos.find(el=>grupo.lunes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.lunes.teacher == el.idTeacher).aceptadoXTeacher == 0){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.martes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.martes.teacher == el.idTeacher).aceptadoXTeacher == 0){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.miercoles.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.miercoles.teacher == el.idTeacher).aceptadoXTeacher == 0){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.jueves.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.jueves.teacher == el.idTeacher).aceptadoXTeacher == 0){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.viernes.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.viernes.teacher == el.idTeacher).aceptadoXTeacher == 0){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.sabado.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.sabado.teacher == el.idTeacher).aceptadoXTeacher == 0){
                                        return true;
                                    }
                                }
                                if(this.teachersPerfectos.find(el=>grupo.domingo.teacher == el.idTeacher)){
                                    if(this.teachersPerfectos.find(el=> grupo.domingo.teacher == el.idTeacher).aceptadoXTeacher == 0){
                                        return true;
                                    }
                                }
                            })
                        }
                    }

                    this.gruposFiltros3 = newgrupos

                    return newgrupos
                }
            },
            filterTeachersAsignacion(){
                const teachers = this.teachersAsignadosFiltrados
                if(teachers){
                    let newteachers = this.teachersAsignadosFiltrados;
                    if(this.teachersSinRolar){
                        newteachers = newteachers.filter(el=>el.teacherReal)
                        newteachers = newteachers.filter(el=>el.teacherReal.idTeacherAsignado==0)
                    }
                    if(this.vacantesLibres){
                        newteachers = newteachers.filter(el=>el.teacherPerfecto)
                        newteachers = newteachers.filter(el=>el.teacherPerfecto.idTeacherAsignado==0)
                    }
                    if(this.plantelFiltro2){
                        newteachers = newteachers.filter(el=>el.teacherPerfecto)
                        newteachers = newteachers.filter(el=>el.teacherPerfecto.id_plantel_prioridad == this.plantelFiltro2)
                    }
                    if(this.prioridadFiltro){
                        newteachers = newteachers.filter(el=>el.teacherPerfecto)
                        newteachers = newteachers.filter(el=>el.teacherPerfecto.prioridad == this.prioridadFiltro && !el.teacherPerfecto.lock)
                    }
                    if(this.frecuenciaFiltro2){
                        newteachers = newteachers.filter(el=>el.teacherPerfecto)
                        if(this.frecuenciaFiltro2 == 1) {
                            newteachers = newteachers.filter(el=>el.teacherPerfecto.frec.D)
                        }
                        if(this.frecuenciaFiltro2 == 2) {
                            newteachers = newteachers.filter(el=>el.teacherPerfecto.frec.S)
                        }
                        if(this.frecuenciaFiltro2 == 3) {
                            newteachers = newteachers.filter(el=>el.teacherPerfecto.frec.LV)
                        }
                    }

                    return newteachers
                }
            },
            filterTeachersAceptacion(){
                const teachers = this.teachersPerfectos.filter(el=>el.idTeacherAsignado)
                if(teachers){
                    let newteachers = teachers;
                    if(this.estatusFiltroLista){
                        if(this.estatusFiltroLista == 0){
                            newteachers = newteachers
                        }
                        if(this.estatusFiltroLista == 1){
                            newteachers = newteachers.filter(el => el.aceptadoXTeacher == 1)
                        }
                        if(this.estatusFiltroLista == 2){
                            newteachers = newteachers.filter(el => el.aceptadoXTeacher == 2)
                        }
                        if(this.estatusFiltroLista == 3){
                            newteachers = newteachers.filter(el => el.aceptadoXTeacher == 0)
                        }
                    }

                    return newteachers
                }
            },
            
        },

        watch: {
            ciclo () {
                if(this.ciclo){
                    this.consultar()
                }
            },
            reglasTodas(){
                if(this.reglasTodas){
                    this.reglaHoras = true
                    this.reglaPlanteles = true
                    this.reglaTeens = true
                    this.reglaDias = true
                    this.reglaTurno = true
                    this.reglaFrec = true
                    this.reglaContinuidad= true
                } else {
                    this.reglaHoras = false
                    this.reglaPlanteles = false
                    this.reglaTeens = false
                    this.reglaDias = false
                    this.reglaTurno = false
                    this.reglaFrec = false
                    this.reglaContinuidad= false
                }
            },

            vacantesLibres(){
                if(this.vacantesLibres){
                    this.teachersSinRolar = false
                }
            },

            teachersSinRolar(){
                if(this.teachersSinRolar){
                    this.vacantesLibres = false
                    this.prioridadFiltro = null
                    this.plantelFiltro2 = null
                    this.frecuenciaFiltro2 = null
                }
            },
        },

        async created () {
            await this.initialize()
        },

        methods: {
            initialize(){
                this.getCiclos()
                this.getPlanteles()
            },

            actualizarPorcentajeAsignacion(){
                this.porcentajeAsignacion = (this.teachersPerfectos.filter(el=>el.idTeacherAsignado!=0 && el.lock).length / this.teachersPerfectos.length)*100
                this.porcentajeAsignacionTexto = this.porcentajeAsignacion.toFixed(2);
            },

            filterTeachersPosibles(){
                this.teachersAsignados.forEach(ta=>{
                    if(ta.teacherPerfecto){
                        ta.teacherPerfecto.TeachersFiltrados = []
                        ta.teacherPerfecto.TeachersFiltrados = ta.teacherPerfecto.Teachers
                        ta.teacherPerfecto.TeachersFiltrados = ta.teacherPerfecto.Teachers.filter(t=>this.teachers.find(tr=>t.idTeacherAsignado == tr.id_usuario).idTeacherAsignado == 0)
                    }
                })
            },

            getPlanteles () {
                this.cargar = true
                this.planteles = []
                return this.$http.get('planteles.activos').then(response=>{
                    this.planteles = response.data
                    this.planteles = this.planteles.filter(el=> ![13,15,18,19,20].includes(el.id_plantel) )
                    this.planteles.forEach(el=>{el.teachers = 0})
                    this.cargar      = false
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { this.cargar = false })
            },

            getCiclos(){
                this.$http.get('roles.ciclos.all').then(response=>{
                    // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
                    // y solo queremos los ciclos que en su nombre dicen ciclo 
                    for(const i in response.data){
                        //  si es diferente a FE
                        if (!(response.data[i].ciclo.search('FE') != -1)) {
                        // Y es igual a FE, se agrega a ciclos
                        // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
                        if(response.data[i].ciclo.search('CICLO') != -1){
                            this.ciclos.push(response.data[i])
                        }
                        }
                    }
                    var fechaActual = new Date();
                    
                    // this.ciclos = this.ciclos.filter(ciclo=> new Date(ciclo.fecha_inicio_ciclo) > fechaActual)
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { this.cargar = false })
            },

            consultar(){
                this.cargar = true
                if( !this.ciclo ){
                    this.cargar = false
                    return true
                }
                let payload = {
                    id_ciclo: this.ciclo.id_ciclo, 
                    id_ciclo_relacionado : this.ciclo.id_ciclo_relacionado,
                    nombre_ciclo : this.ciclo.ciclo, 
                    reglaHoras:this.reglaHoras,
                    reglaPlanteles:this.reglaPlanteles,
                    reglaTeens:this.reglaTeens,
                    reglaDias:this.reglaDias,
                    reglaFrec:this.reglaFrec,
                    reglaTurno:this.reglaTurno,
                    reglaContinuidad:this.reglaContinuidad
                }
                
                this.$http.post('rol.clases3', payload).then(response=>{
                    if(this.reglaHoras && this.reglaPlanteles && this.reglaTeens && this.reglaDias && this.reglaFrec && this.reglaTurno && this.reglaContinuidad){
                        this.consultaConTodasLasReglas = true;
                    } else {
                        this.consultaConTodasLasReglas = false;
                    }
                    //this.planteles = response.data.planteles
                    this.grupos = response.data.grupos
                    this.teachersPerfectos = response.data.teachersPerfectos

                    this.teachersPerfectosTotales = response.data.teachersPerfectosTotales
                    this.teachersPerfectosMujeres = response.data.teachersPerfectosMujeres
                    this.teachersPerfectosOnline = response.data.teachersPerfectosOnline
                    this.teachersPerfectosSabatinos = response.data.teachersPerfectosSabatinos
                    this.teachersPerfectosDominicales = response.data.teachersPerfectosDominicales
                    this.teachersPerfectosMatutino = response.data.teachersPerfectosMatutino
                    this.teachersPerfectosVespertino = response.data.teachersPerfectosVespertino

                    this.planteles.forEach(el=>{ 
                        el.teachers = response.data.planteles.find(i => i.id_plantel == el.id_plantel).teachers 
                        el.teachersMujeres = response.data.planteles.find(i => i.id_plantel == el.id_plantel).teachersMujeres 
                        el.teachersMat = response.data.planteles.find(i => i.id_plantel == el.id_plantel).teachersMat 
                        el.teachersVes = response.data.planteles.find(i => i.id_plantel == el.id_plantel).teachersVes 
                        el.teachersLV = response.data.planteles.find(i => i.id_plantel == el.id_plantel).teachersLV 
                        el.teachersS = response.data.planteles.find(i => i.id_plantel == el.id_plantel).teachersS 
                        el.teachersD = response.data.planteles.find(i => i.id_plantel == el.id_plantel).teachersD 
                    })
                    this.niveles = response.data.grupos.map((grupo) => { return grupo.nivel }).sort((a,b)=>{ return a - b })

                    this.horarios = response.data.grupos.map((grupo) => { return grupo.hora_inicio }).sort((a,b)=>{
                        var horaInicioA = new Date("1970-01-01T" + a + "Z");
                        var horaInicioB = new Date("1970-01-01T" + b + "Z");

                        return horaInicioA - horaInicioB
                    })

                    this.existeAsignacion=response.data.existeAsignacion;
                    this.sumSueldos = response.data.sumSueldos
                    this.sumSueldosCiclo = response.data.sumSueldosCiclo
                    
                    this.teachers = response.data.teachers

                    this.teachersAsignados =response.data.teachersAsignados
                    this.teachersAsignadosFiltrados =response.data.teachersAsignados
                    this.teachersAsignadosFiltrados.forEach(el=>{
                        //let plantel = this.planteles.find(el=>el.id_plantel == el.teacherPerfecto.id_plantel_prioridad)
                        //let sexo = el.teacherPerfecto.sexo == 'M' ? ' - Teacher Kids/Teens' : ''
                        if(el.teacherPerfecto){
                            el.teacherPerfectoNombre = el.teacherPerfecto.idTeacher + ' - ' + el.teacherPerfecto.nombre //+ ' - Plantel:'  + plantel.plantel + sexo
                        } else {
                            el.teacherPerfectoNombre = ' No hay match '
                        }

                        //let plantel2 = this.planteles.find(el=>el.id_plantel == el.teacherReal.id_plantel)
                        if(el.teacherReal){
                            el.teacherRealNombre = el.teacherReal.id_usuario + ' - ' + el.teacherReal.nombre_completo //+ ' - Plantel:' + plantel2.plantel
                        } else {
                            el.teacherRealNombre = ' Vacante '
                        }
                    })
                    
                    this.teachersSR = this.teachers.filter(el=>el.idTeacherAsignado==0)
                    
                    this.rolLiberado = response.data.rolLiberado
                    this.rolGenerado = response.data.rolGenerado

                    this.porcentajeAceptacion = (this.teachersPerfectos.filter(el=>el.aceptadoXTeacher == 1).length / this.teachersPerfectosTotales)*100
                    this.porcentajeAceptacion = this.porcentajeAceptacion.toFixed(2);
                    this.generarReporteTV()
                    this.filterTeachersPosibles()
                    this.actualizarPorcentajeAsignacion()
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { 
                    this.cargar = false
                })
            },

            mostrarDialogReemplazarTeacher(grupo, teacherViejo){
                let teacher = this.teachersPerfectos.find(el=>el.idTeacher == teacherViejo)
                if(this.rolLiberado && (teacher.aceptadoXTeacher==2)){
                    let auxRoles = this.teachers.filter(el=> teacher.Teachers.find(t=>t.idTeacherAsignado == el.id_usuario && t.idTeacherAsignado != teacher.idTeacherAsignado && t.porcentaje == 100))
                    this.posiblesReemplazos = auxRoles.filter(aux=> this.teachersPerfectos.find(el=>el.idTeacherAsignado == aux.idTeacherAsignado && el.aceptadoXTeacher == 2) || aux.idTeacherAsignado == 0)
                    this.teacherAReemplazar = this.teachersPerfectos.find(el=>el.idTeacher == teacherViejo) 
                    this.dialogReemplazarTeacher = true;
                }
            },

            confirmarReemplazarTeacher(){
                if(this.existeAsignacion && this.teacherReemplazo){
                    this.cargar = true
                    let payload = {
                        reemplazarTeacher: {
                            teacherViejo: this.teacherAReemplazar,
                            teacherNuevo: this.teacherReemplazo,
                        },
                        id_ciclo:this.ciclo
                    }
                    this.$http.post('rol.reemplazarTeacherRolJSON', payload).then(response=>{
                        this.consultar()
                        this.filterGruposPreRol
                        this.dialogReemplazarTeacher=false
                        this.validarSuccess('Reemplazo realizado')
                        this.teacherReemplazo = null
                    }).catch( error =>{
                    this.validarError( error.response.data.message )
                    }).finally( () => { 
                        this.cargar = false
                    })
                }
            },

            generarReporteTV(){
                this.teachersVacantesReporte = {
                    vacantes : [],
                    mujeres : 0,
                    planteles : [],
                    online : 0,
                    horarioLVMAt: 0,
                    horarioLVVes: 0,
                    horarioSab:0,
                    horarioDom:0,
                }
                this.teachersVacantesReporte.vacantes = this.teachersPerfectos.filter(el=>el.idTeacherAsignado == 0)
                this.teachersVacantesReporte.mujeres = this.teachersVacantesReporte.vacantes.filter(el=>el.sexo=='M').length
                this.planteles.forEach(plantel=>{
                    let cantidad = this.teachersVacantesReporte.vacantes.filter(el=>el.id_plantel_prioridad==plantel.id_plantel).length
                    let tmujeres = this.teachersVacantesReporte.vacantes.filter(el=>el.id_plantel_prioridad==plantel.id_plantel && el.sexo=='M').length
                    let thorarioV = this.teachersVacantesReporte.vacantes.filter(el=>el.id_plantel_prioridad==plantel.id_plantel && el.turno.V).length
                    let thorarioM = this.teachersVacantesReporte.vacantes.filter(el=>el.id_plantel_prioridad==plantel.id_plantel && el.turno.M).length
                    let thorarioS = this.teachersVacantesReporte.vacantes.filter(el=>el.id_plantel_prioridad==plantel.id_plantel && el.frec.S).length
                    let thorarioD = this.teachersVacantesReporte.vacantes.filter(el=>el.id_plantel_prioridad==plantel.id_plantel && el.frec.D).length
                    this.teachersVacantesReporte.planteles.push({
                        id_plantel: plantel.id_plantel,
                        plantel: plantel.plantel, 
                        teachers: cantidad,
                        teachersMujeres: tmujeres,
                        teachersMat: thorarioM,
                        teachersVes : thorarioV,
                        teachersS : thorarioS,
                        teachersD : thorarioD,
                    })
                })
                this.teachersVacantesReporte.online = this.teachersVacantesReporte.vacantes.filter(el=>el.online).length
                this.teachersVacantesReporte.horarioLVMAt = this.teachersVacantesReporte.vacantes.filter(el=>el.turno.M).length
                this.teachersVacantesReporte.horarioLVVes = this.teachersVacantesReporte.vacantes.filter(el=>el.turno.V).length
                this.teachersVacantesReporte.horarioSab = this.teachersVacantesReporte.vacantes.filter(el=>el.frec.S).length
                this.teachersVacantesReporte.horarioDom = this.teachersVacantesReporte.vacantes.filter(el=>el.frec.D).length
            },

            guardarJSON(){
                if(this.ciclo && !this.existeAsignacion){
                    let payload = {
                        paquetes:this.teachersPerfectos,
                        id_ciclo:this.ciclo
                    }
                    this.$http.post('rol.generarJSON', payload).then(response=>{
                        this.existeAsignacion = true;
                        this.alertaAsignacion2 = true;
                    }).catch( error =>{
                        this.validarError( error.response.data.message )
                    }).finally( () => { 
                        this.cargar = false
                        this.dialogTeachers=true
                    })
                }
            },

            actualizarJSONLock(teacher, aceptar){
                //agregar nombre de teacher real para mostrar en prerol
                if(this.existeAsignacion){
                    let payload = {
                        aceptarAsignacion: {
                            idTeacherPerfecto : teacher.teacherPerfecto.idTeacher,
                            idTeacherAsignado : teacher.teacherReal.id_usuario,
                            lock: aceptar,
                        },
                        id_ciclo:this.ciclo
                    }
                    this.$http.post('rol.aceptarAsignacionJSON', payload).then(response=>{
                        teacher.teacherPerfecto.lock = aceptar
                        this.teachersPerfectos.forEach(el=>{
                            if(el.idTeacher == teacher.teacherPerfecto.idTeacher){
                                el.lock = aceptar
                                if(aceptar){
                                    el.idTeacherAsignado = teacher.teacherReal.id_usuario
                                }
                                el.grupos.forEach(grupo=>{
                                    grupo.lunes.idteacherReal = grupo.lunes.teacher == el.idTeacher ? el.idTeacherAsignado : 0
                                    grupo.martes.idteacherReal = grupo.martes.teacher == el.idTeacher ? el.idTeacherAsignado : 0
                                    grupo.miercoles.idteacherReal = grupo.miercoles.teacher == el.idTeacher ? el.idTeacherAsignado : 0
                                    grupo.jueves.idteacherReal = grupo.jueves.teacher == el.idTeacher ? el.idTeacherAsignado : 0
                                    grupo.viernes.idteacherReal = grupo.viernes.teacher == el.idTeacher ? el.idTeacherAsignado : 0
                                    grupo.sabado.idteacherReal = grupo.sabado.teacher == el.idTeacher ? el.idTeacherAsignado : 0
                                    grupo.domingo.idteacherReal = grupo.domingo.teacher == el.idTeacher ? el.idTeacherAsignado : 0

                                    grupo.lunes.teacherReal = grupo.lunes.teacher == el.idTeacher ? this.teachers.find(TR=>TR.id_usuario == el.idTeacherAsignado).nombre_completo : ''
                                    grupo.martes.teacherReal = grupo.martes.teacher == el.idTeacher ? this.teachers.find(TR=>TR.id_usuario == el.idTeacherAsignado).nombre_completo : ''
                                    grupo.miercoles.teacherReal = grupo.miercoles.teacher == el.idTeacher ? this.teachers.find(TR=>TR.id_usuario == el.idTeacherAsignado).nombre_completo : ''
                                    grupo.jueves.teacherReal = grupo.jueves.teacher == el.idTeacher ? this.teachers.find(TR=>TR.id_usuario == el.idTeacherAsignado).nombre_completo : ''
                                    grupo.viernes.teacherReal = grupo.viernes.teacher == el.idTeacher ? this.teachers.find(TR=>TR.id_usuario == el.idTeacherAsignado).nombre_completo : ''
                                    grupo.sabado.teacherReal = grupo.sabado.teacher == el.idTeacher ? this.teachers.find(TR=>TR.id_usuario == el.idTeacherAsignado).nombre_completo : ''
                                    grupo.domingo.teacherReal = grupo.domingo.teacher == el.idTeacher ? this.teachers.find(TR=>TR.id_usuario == el.idTeacherAsignado).nombre_completo : ''
                                })
                            }
                        })
                        this.grupos.forEach(grupo=>{
                            grupo.lunes.idteacherReal = this.teachersPerfectos.find(el=>el.idTeacher == grupo.lunes.teacher && el.lock) ? this.teachersPerfectos.find(el=>el.idTeacher == grupo.lunes.teacher).idTeacherAsignado : 0
                            grupo.martes.idteacherReal = this.teachersPerfectos.find(el=>el.idTeacher == grupo.martes.teacher && el.lock) ? this.teachersPerfectos.find(el=>el.idTeacher == grupo.martes.teacher).idTeacherAsignado : 0
                            grupo.miercoles.idteacherReal = this.teachersPerfectos.find(el=>el.idTeacher == grupo.miercoles.teacher && el.lock) ? this.teachersPerfectos.find(el=>el.idTeacher == grupo.miercoles.teacher).idTeacherAsignado : 0
                            grupo.jueves.idteacherReal = this.teachersPerfectos.find(el=>el.idTeacher == grupo.jueves.teacher && el.lock) ? this.teachersPerfectos.find(el=>el.idTeacher == grupo.jueves.teacher).idTeacherAsignado : 0
                            grupo.viernes.idteacherReal = this.teachersPerfectos.find(el=>el.idTeacher == grupo.viernes.teacher && el.lock) ? this.teachersPerfectos.find(el=>el.idTeacher == grupo.viernes.teacher).idTeacherAsignado : 0
                            grupo.sabado.idteacherReal = this.teachersPerfectos.find(el=>el.idTeacher == grupo.sabado.teacher && el.lock) ? this.teachersPerfectos.find(el=>el.idTeacher == grupo.sabado.teacher).idTeacherAsignado : 0
                            grupo.domingo.idteacherReal = this.teachersPerfectos.find(el=>el.idTeacher == grupo.domingo.teacher && el.lock) ? this.teachersPerfectos.find(el=>el.idTeacher == grupo.domingo.teacher).idTeacherAsignado : 0

                            grupo.lunes.teacherReal = grupo.lunes.idteacherReal ? this.teachers.find(TR=>TR.id_usuario == grupo.lunes.idteacherReal).nombre_completo : ''
                            grupo.martes.teacherReal = grupo.martes.idteacherReal ? this.teachers.find(TR=>TR.id_usuario == grupo.martes.idteacherReal).nombre_completo : ''
                            grupo.miercoles.teacherReal = grupo.miercoles.idteacherReal ? this.teachers.find(TR=>TR.id_usuario == grupo.miercoles.idteacherReal).nombre_completo : ''
                            grupo.jueves.teacherReal = grupo.jueves.idteacherReal ? this.teachers.find(TR=>TR.id_usuario == grupo.jueves.idteacherReal).nombre_completo : ''
                            grupo.viernes.teacherReal = grupo.viernes.idteacherReal ? this.teachers.find(TR=>TR.id_usuario == grupo.viernes.idteacherReal).nombre_completo : ''
                            grupo.sabado.teacherReal = grupo.sabado.idteacherReal ? this.teachers.find(TR=>TR.id_usuario == grupo.sabado.idteacherReal).nombre_completo : ''
                            grupo.domingo.teacherReal = grupo.domingo.idteacherReal ? this.teachers.find(TR=>TR.id_usuario == grupo.domingo.idteacherReal).nombre_completo : ''

                        })
                    }).catch( error =>{
                        this.validarError( error.response.data.message )
                    }).finally( () => { 
                        this.actualizarPorcentajeAsignacion()
                        this.cargar = false
                     })
                }
                
            },

            actualizarJSONPrioridad(teacher){
                if(this.existeAsignacion){
                    let payload = {
                        cambiarPrioridad: {
                            idTeacherPerfecto : teacher.teacherPerfecto.idTeacher,
                            prioridad: teacher.teacherPerfecto.prioridad,
                        },
                        id_ciclo:this.ciclo
                    }
                    this.$http.post('rol.cambiarPrioridadJSON', payload).then(response=>{
                        this.teachersPerfectos.forEach(el=>{
                            if(el.idTeacher == teacher.teacherPerfecto.idTeacher){
                                el.prioridad = teacher.teacherPerfecto.prioridad
                            }
                        })
                    }).catch( error =>{
                        this.validarError( error.response.data.message )
                    }).finally( () => { this.cargar = false })
                }
            },

            liberarRol(){
                //actualizar paquetes con estatus liberado
                if(this.ciclo){
                    this.cargar = true;
                    this.confirmarLiberacionPreRol = false
                    let payload = {
                        liberado: true,
                        id_ciclo:this.ciclo
                    }
                    this.$http.post('rol.liberarRolJSON', payload).then(response=>{
                        this.rolLiberado = true
                        this.dialogPreRol = true
                        this.alertaAsignacion3 = true
                    }).catch( error =>{
                        this.validarError( error.response.data.message )
                    }).finally( () => { 
                        this.cargar = false
                    })
                }
            },

            generarRol(){
                if(this.porcentajeAceptacion == 100){
                    this.cargar = true;
                    this.dialogPreRol = false;
                    this.confirmarGenerarRol = false
                    let payload = {
                        generado: true,
                        id_ciclo:this.ciclo
                    }
                    this.$http.post('rol.generarRolJSON', payload).then(response=>{
                        this.validarSuccess( 'Rol final generado' )
                    }).catch( error =>{
                        this.dialogPreRol = true;
                        this.validarError( error.response.data.message )
                    }).finally( () => { 
                        this.cargar = false
                        this.rolGenerado = true
                        this.confirmarGenerarRol = false
                    })
                } else {
                    this.validarErrorDirecto('No cuenta con el 100% de aceptacion por parte de los Teachers')
                }
            },

            mostrarPreRol(){
                if(!this.rolLiberado){
                    this.confirmarLiberacionPreRol = true
                } else {
                    this.dialogPreRol = true
                }
            },

            asignarTeacher(teacher, teacherP){
                //creamos un bool que nos dira si el teacher posible ya fue asignado en otro lado
                let yaEstaAsignado = false; let estaLockeado = false;
                //recorremos los teachers perfectos
                this.teachersPerfectos.forEach(el=>{
                    //si el idTeacherAsignado es igual al idTeacherAsignado del teacher posible y aparte esta lockeado entonces el bool cambia a verdadero
                    if(el.idTeacherAsignado == teacherP.idTeacherAsignado){
                        yaEstaAsignado = true
                        if(el.lock){
                            estaLockeado = true;
                        }
                    }
                })
                //si el teacher perfecto donde queremos asignarlo no esta lockeado y el teacher posible tampoco ha sido asignado en otro lado entonces hacemos la asignacion
                if(!teacher.teacherPerfecto.lock && !estaLockeado && !yaEstaAsignado){
                    //buscamos la info completa del teacher real
                    let teacherRealNuevo = this.teachers.find(el=>el.id_usuario == teacherP.idTeacherAsignado)
                    let teacherRealAnterior = this.teachers.find(el=>el.id_usuario == teacher.teacherPerfecto.idTeacherAsignado)
                    if(teacherRealAnterior){
                        teacherRealAnterior.idTeacherAsignado = 0
                    }
                    let teacherPerfecto = this.teachersPerfectos.find(el=>el.idTeacher == teacher.teacherPerfecto.idTeacher)
                    teacherPerfecto.idTeacherAsignado = teacherP.idTeacherAsignado
                    //hacemos la asignacion de ids
                    teacherRealNuevo.idTeacherAsignado = teacher.teacherPerfecto.idTeacher
                    teacher.teacherPerfecto.idTeacherAsignado = teacherP.idTeacherAsignado
                    teacher.teacherReal = teacherRealNuevo;
                    //actualizamos la lista de manera manual para que se refleje en pantalla el cambio
                    let teachersAux = this.teachersAsignadosFiltrados
                    this.teachersAsignadosFiltrados = []
                    this.teachersAsignadosFiltrados = teachersAux

                    this.teachersAsignadosFiltrados.forEach(el=>{
                        //let plantel = this.planteles.find(el=>el.id_plantel == el.teacherPerfecto.id_plantel_prioridad)
                        //let sexo = el.teacherPerfecto.sexo == 'M' ? ' - Teacher Kids/Teens' : ''
                        if(el.teacherPerfecto){
                            el.teacherPerfectoNombre = el.teacherPerfecto.idTeacher + ' - ' + el.teacherPerfecto.nombre //+ ' - Plantel:' // + plantel.plantel + sexo
                        } else {
                            el.teacherPerfectoNombre = ' No hay match '
                        }

                        //let plantel2 = this.planteles.find(el=>el.id_plantel == el.teacherReal.id_plantel)
                        if(el.teacherReal){
                            el.teacherRealNombre = el.teacherReal.id_usuario + ' - ' + el.teacherReal.nombre_completo //+ ' - Plantel:' //+ plantel2.plantel
                        } else {
                            el.teacherRealNombre = ' Vacante '
                        }
                    })
                    this.filterTeachersPosibles()
                } else {
                    //en caso se ya estar lockeado tanto el teacher perfecto como el teacher posible se muestra una alerta
                    this.alertaAsignacion = true;
                }
                
            },
            
        }
    }

</script>

<style>
    .v-data-table__wrapper{

    }

    .v-data-table__wrapper::-webkit-scrollbar {
        -webkit-appearance: none !important;
    }

    .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
        width: 10px !important;
    }

    .v-data-table__wrapper::-webkit-scrollbar:vertical{
        width: 10px !important;
    }

    .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
        display: none;
    }

    .v-data-table__wrapper::-webkit-scrollbar:horizontal{
        height: 10px !important;
    }

    .v-data-table__wrapper::-webkit-scrollbar-thumb{
        background-color: #BABCBF;
        border-radius: 20px;
        border: 1px solid #ededed;
    }

    #yellow{
        background-color: yellow !important;
    }

</style>